import { render, staticRenderFns } from "./InvoiceStats.vue?vue&type=template&id=213ad1d4&scoped=true"
import script from "./InvoiceStats.vue?vue&type=script&lang=js"
export * from "./InvoiceStats.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "213ad1d4",
  null
  
)

export default component.exports